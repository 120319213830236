import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
`;

const Title = styled.h1`
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
`;

const Section = styled.section`
    margin-bottom: 20px;
`;

const PrivacyPolicy = () => {
    const data = [
        {
            'title'     : "Pendahuluan",
            'content'   : `Selamat datang di Buffet Ahmad Salim. Kebijakan Privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan melindungi informasi pribadi Anda ketika Anda menggunakan aplikasi kepegawaian kami (secara kolektif disebut "Layanan"). Dengan menggunakan Layanan kami, Anda setuju dengan pengumpulan dan penggunaan informasi sesuai dengan kebijakan ini.`,
        },
        {
            'title'     : "2. Informasi yang Kami Kumpulkan",
            'content'   : ``,
        },
        {
            'title'     : "2.1 Informasi Pribadi",
            'content'   : `
                Ketika Anda menggunakan Layanan kami, kami mungkin mengumpulkan informasi pribadi yang Anda berikan langsung kepada kami, termasuk:
                Informasi Identifikasi Pribadi: Nama, alamat email, nomor telepon, dan informasi pembayaran.
                Detail Karyawan: Posisi, jadwal kerja, dan informasi terkait pekerjaan lainnya.
            `,
        },
        {
            'title'     : "2.2 Data Penggunaan",
            'content'   : `
                Kami juga dapat mengumpulkan informasi tentang interaksi Anda dengan Layanan kami, termasuk:
                Informasi Perangkat: Alamat IP, tipe browser, sistem operasi, dan identifikasi perangkat.
                Data Penggunaan: Halaman yang dikunjungi, waktu yang dihabiskan di halaman, dan statistik penggunaan lainnya.
            `,
        },
        {
            'title'     : "2.3 Cookies dan Teknologi Pelacakan",
            'content'   : `
                Kami menggunakan cookies dan teknologi pelacakan serupa untuk meningkatkan pengalaman Anda di Layanan kami. Cookies adalah file data kecil yang disimpan di perangkat Anda yang membantu kami mengingat preferensi Anda dan meningkatkan fungsionalitas. Anda dapat mengatur browser Anda untuk menolak cookies, tetapi ini dapat membatasi kemampuan Anda untuk menggunakan beberapa fitur dari Layanan kami.
            `,
        },
        {
            'title'     : "3. Cara Kami Menggunakan Informasi Anda",
            'content'   : `
                Kami menggunakan informasi yang kami kumpulkan untuk tujuan berikut:

                Untuk Menyediakan dan Meningkatkan Layanan Kami: Mengelola jadwal kerja, menangani penggajian, dan meningkatkan pengalaman pengguna.
                Untuk Berkomunikasi dengan Anda: Mengirim email konfirmasi, merespons pertanyaan, dan memberikan pembaruan tentang Layanan kami.
                Untuk Memproses Pembayaran: Menangani transaksi dengan aman dan mencegah penipuan.
                Untuk Menganalisis Penggunaan: Memantau dan menganalisis pola penggunaan untuk meningkatkan Layanan kami.
                Untuk Pemasaran: Mengirim materi promosi, penawaran, dan buletin, dengan persetujuan Anda.
            `,
        },
        {
            'title'     : "4. Bagaimana Kami Membagikan Informasi Anda",
            'content'   : `
                Dengan Penyedia Layanan: Vendor pihak ketiga yang membantu kami dalam menjalankan Layanan kami, seperti pemroses pembayaran dan agen pemasaran.
                Untuk Alasan Hukum: Ketika diperlukan oleh hukum atau untuk melindungi hak, properti, atau keselamatan [Nama Restoran], pengguna kami, atau orang lain.
                Transfer Bisnis: Dalam hal merger, akuisisi, atau penjualan aset, di mana informasi Anda mungkin dipindahkan ke entitas lain.
            `,
        },
        {
            'title'     : "5. Keamanan Data",
            'content'   : `
                Kami menerapkan langkah-langkah keamanan yang wajar untuk melindungi informasi pribadi Anda dari akses, penggunaan, atau pengungkapan yang tidak sah. Namun, tidak ada metode transmisi melalui internet atau penyimpanan elektronik yang 100% aman. Meskipun kami berusaha keras untuk melindungi informasi Anda, kami tidak dapat menjamin keamanan mutlaknya.
            `,
        },
        {
            'title'     : "6. Pilihan dan Hak Anda",
            'content'   : `
                Anda memiliki hak tertentu terkait informasi pribadi Anda, termasuk:
                Akses dan Koreksi: Meminta akses atau koreksi terhadap informasi pribadi Anda.
                Penghapusan: Meminta penghapusan informasi pribadi Anda, dengan pengecualian hukum tertentu.
                Menolak: Menolak menerima komunikasi pemasaran dari kami dengan mengikuti instruksi berhenti berlangganan dalam komunikasi tersebut.
                Untuk menggunakan hak Anda atau jika Anda memiliki pertanyaan atau kekhawatiran tentang informasi Anda, silakan hubungi kami di it.hijrah@gmail.com.
            `,
        },
        {
            'title'     : "7. Privasi Anak-Anak",
            'content'   : `
                Layanan kami tidak ditujukan untuk anak-anak di bawah usia 13 tahun. Kami tidak secara sengaja mengumpulkan informasi pribadi dari anak-anak di bawah 13 tahun. Jika kami mengetahui bahwa kami telah mengumpulkan informasi pribadi dari anak tanpa izin orang tua, kami akan mengambil langkah-langkah untuk menghapus informasi tersebut.
            `,
        },        
        {
            'title'     : "8. Perubahan pada Kebijakan Privasi Ini",
            'content'   : `
                Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Setiap perubahan akan diposting di halaman ini dengan tanggal efektif yang diperbarui. Kami mendorong Anda untuk meninjau Kebijakan Privasi ini secara berkala untuk tetap mengetahui praktik kami.
            `,
        },
    ]

    return (
        <Container>
            <Title>Privacy Policy</Title>
            {data.map(item => {
                return (<Section>
                    <h1 className='font-weight-bold'>{item.title}</h1>
                    <p>{item.content}</p>
                </Section>)
            })}
        </Container>
    );
};

export default PrivacyPolicy;
