import DataTable from "./Table/DataTable";
import Grid from "./Grid/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import List from "./List/List";

const RenderComponent = ({ component, type, attribute, actionRemove, actionEdit, actionRequest, callRequest, enableCustom }) => {

    const Container = ({ label, content}) => {
        return (
            <div className="card">
                {enableCustom && 
                    <div className="rounded bg-gray-100 border-b border-gray-200 flex px-4 py-2">
                        <h2 className="text-lg font-semibold text-gray-900 flex-1">{label}</h2>
                        <button
                            className="text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 mx-1"
                            onClick={() => actionRemove(component)}>
                            <FontAwesomeIcon icon={faTrashAlt} color="white" />
                        </button>
                        <button
                            data-tip="This is a tooltip"
                            className="text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1"
                            onClick={() => actionEdit(component)}>
                            <FontAwesomeIcon icon={faEdit} color="white" />
                        </button>
                        <button
                            data-tip="This is a tooltip"
                            className="text-xs rounded focus:ring bg-green-500 hover:bg-green-700 p-2 mx-1"
                            onClick={() => actionRequest(component)}>
                            <FontAwesomeIcon icon={faPaperPlane} color="white" />
                        </button>
                    </div> }                
                <div className="p-4 w-full">
                    {content}
                </div>
            </div>
        );
    }

    switch (type) {
        case 'button':
            const buttonAttributes = { ...attribute };
            return (
                <Container label={component?.title ?? "Button"} content={<button {...buttonAttributes} onClick={callRequest}>{attribute.child}</button>} />
            );
        case 'input':
            const inputAttributes = { ...attribute };
            return (
                <Container label={component?.title ?? "Input"} content={<input {...inputAttributes} />} />
            );
        case 'text':
            return <p>{component}</p>;
        case 'header':
            return <header>{component}</header>;
        case 'footer':
            return <footer style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                right: 0,
            }}>{component}</footer>;
        case 'card':
            return (
                <div className="card">
                    {component}
                </div>
            );
        case 'section':
            return (
                <section>{component}</section>
            );
        case 'grid':
            return (
                <Container label={component?.title ?? "Grid"} content={<Grid items={component.items} columns={attribute.columns} />} />
            );
        case 'table':
            return (
                <Container label={component?.title ?? "Table"} content={<DataTable data={component.items} columns={component.columns} />} />
            );
        case 'list':
            return (
                <Container label={component?.title ?? "List"} content={<List items={component.items} />} />
            )
        default:
            return null;
    }
};

export default RenderComponent;