import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

const EntryShift = () => {
    const { id } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [initialValues, setInitialValues] = useState({
        id: '',
        shift: '',
        time_start: '',
        many_hours: '',
        time_rest: '',
        many_rest: '',
        hours_start_after: '',
        hours_start_before: '',
        hours_end_after: '',
        hours_end_before: ''
    });

    useEffect(() => {        
        fetchData();
    }, [id]);
    
    const fetchData = async () => {
        setLoading(true);
        try {
            if (id) {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/attendance/shift/detail?id=${id}`);
                const { shift, time_start, time_rest, many_rest, many_hours, hours_start_after, hours_start_before, hours_end_after, hours_end_before } = response.data;
                setInitialValues({
                    id: id,
                    shift: shift || '',
                    time_start: time_start || '',
                    many_hours: many_hours || '',
                    time_rest: time_rest || '',
                    many_rest: many_rest || '',
                    hours_start_after: hours_start_after || '',
                    hours_start_before: hours_start_before || '',
                    hours_end_after: hours_end_after || '',
                    hours_end_before: hours_end_before || ''
                });                
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        shift: Yup.string().required('shift is required'),
        time_start: Yup.string().required('timeStart is required'),
        many_hours: Yup.string().required('manyHours is required'),
        // time_rest: Yup.string().required("time_rest is required"),
        // many_rest: Yup.string().required("many_rest is required"),
        hours_start_after: Yup.string().required('hoursStartAfter is required'),
        hours_start_before: Yup.string().required('hoursStartBefore is required'),
        hours_end_after: Yup.string().required('hoursEndAfter is required'),
        hours_end_before: Yup.string().required('hoursEndBefore is required'),
    });

    // Handle form submission
    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: '1',
                shift: values.shift,
                time_start: values.time_start,
                many_hours: values.many_hours,
                time_rest: values.time_rest,
                many_rest: values.many_rest,
                hours_start_after: values.hours_start_after,
                hours_start_before: values.hours_start_before,
                hours_end_after: values.hours_end_after,
                hours_end_before: values.hours_end_before
            };

            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_URL}api/core/attendance/shift`, formData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}api/core/attendance/shift`, formData);
            }
            
            navigate(-1);
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error, show alert or set form error state
        } finally {
            requests.setSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 bg-white shadow-md rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form Module</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        {/* Form fields */}
                        <div className="flex flex-col md:flex-row gap-4 p-4">
                            <div className='flex-1'>

                            <div className="mb-4">
                                <label htmlFor="shift" className="block text-sm font-medium text-gray-700">
                                    Shift
                                </label>
                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Shift"
                                    type="text"
                                    name="shift"
                                    defaultValue={initialValues.shift}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.shift && touched.shift && (
                                    <p className="text-red-500 text-xs mt-1">{errors.shift}</p>
                                )}
                            </div>
                            <div className="mb-4 flex">
                                <div className='flex-1 pr-2'>
                                    <label htmlFor="time_start" className="block text-sm font-medium text-gray-700">
                                        Jam Masuk
                                    </label>
                                    <input
                                        className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        placeholder="Jam Masuk"
                                        type="time"
                                        name="time_start"
                                        defaultValue={initialValues.time_start}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.time_start && touched.time_start && (
                                        <p className="text-red-500 text-xs mt-1">{errors.time_start}</p>
                                    )}
                                </div>
                                <div className='flex-1 pl-2'>
                                    <label htmlFor="many_hours" className="block text-sm font-medium text-gray-700">
                                        Lama Jam Kerja
                                    </label>
                                    <input
                                        className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        placeholder="Lama Jam Kerja"
                                        type="number"
                                        name="many_hours"
                                        defaultValue={initialValues.many_hours}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.many_hours && touched.many_hours && (
                                        <p className="text-red-500 text-xs mt-1">{errors.many_hours}</p>
                                    )}
                                </div>
                            </div>
                            <div className="mb-4 flex">
                                <div className="flex-1 pr-2">
                                    <label htmlFor="time_start" className="block text-sm font-medium text-gray-700">
                                        Jam Mulai Istirahat
                                    </label>
                                    <input
                                        className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        placeholder="Jam Mulai Istirahat"
                                        type="time"
                                        name="time_rest"
                                        defaultValue={initialValues.time_rest}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.time_rest && touched.time_rest && (
                                        <p className="text-red-500 text-xs mt-1">{errors.time_rest}</p>
                                    )}
                                </div>
                                <div className="flex-1 pl-2">
                                    <label htmlFor="many_rest" className="block text-sm font-medium text-gray-700">
                                        Lama Jam Istirahat
                                    </label>
                                    <input
                                        className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        placeholder="Lama Jam Istirahat"
                                        type="number"
                                        name="many_rest"
                                        defaultValue={initialValues.many_rest}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.many_rest && touched.many_rest && (
                                        <p className="text-red-500 text-xs mt-1">{errors.many_rest}</p>
                                    )}
                                </div>                            
                            </div>
                            <div className="mb-4">
                                <label htmlFor="hours_start_before" className="block text-sm font-medium text-gray-700">
                                    Waktu Masuk Kerja Dibuka
                                </label>
                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Waktu Masuk Kerja Dibuka"
                                    type="text"
                                    name="hours_start_before"
                                    defaultValue={initialValues.hours_start_before}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.hours_start_before && touched.hours_start_before && (
                                    <p className="text-red-500 text-xs mt-1">{errors.hours_start_before}</p>
                                )}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="many_hours" className="block text-sm font-medium text-gray-700">
                                    Waktu Toleransi Masuk Kerja
                                </label>
                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Waktu Toleransi Masuk Kerja"
                                    type="number"
                                    name="hours_start_after"
                                    defaultValue={initialValues.hours_start_after}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.hours_start_after && touched.hours_start_after && (
                                    <p className="text-red-500 text-xs mt-1">{errors.hours_start_after}</p>
                                )}
                            </div>                        
                            <div className="mb-4">
                                <label htmlFor="many_hours" className="block text-sm font-medium text-gray-700">
                                Waktu Pulang Toleransi Kerja
                                </label>
                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Waktu Pulang Toleransi Kerja"
                                    type="number"
                                    name="hours_end_after"
                                    defaultValue={initialValues.hours_end_after}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.hours_end_after && touched.hours_end_after && (
                                    <p className="text-red-500 text-xs mt-1">{errors.hours_end_after}</p>
                                )}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="many_hours" className="block text-sm font-medium text-gray-700">
                                Waktu Pulang Kerja Dibuka
                                </label>
                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Waktu Pulang Kerja Dibuka"
                                    type="text"
                                    name="hours_end_before"
                                    defaultValue={initialValues.hours_end_before}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.hours_end_before && touched.hours_end_before && (
                                    <p className="text-red-500 text-xs mt-1">{errors.hours_end_before}</p>
                                )}
                            </div>
                            <div className="mt-4">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                >
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                            </div>

                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryShift;
