// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Auth from './pages/Auth';
import Dashboard from './pages/Dashboard';
import NotFoundPage from './pages/NotFoundPage';
import ProtectedRoute from './component/ProtectedRoute';
import Entity from './pages/Entity';
import Activity from './pages/Activity';
import Module from './pages/Module';
import EntryRequest from './pages/Activity/Component/EntryRequest';
import EntryActionCompnent from './pages/Activity/Component/EntryActionComponent';
import EntryEntity from './pages/Entity/Entry';
import EntryModule from './pages/Module/Entry';
import User from './core/user';
import EntryUser from './core/user/Entry';
import Shift from './core/attendance/shift';
import EntryShift from './core/attendance/shift/Entry';
import EntrySchedule from './core/attendance/schedule/Entry';
import './App.css';
import Employee from './core/attendance/employee';
import EntryEmployee from './core/attendance/employee/Entry';
import EntryOvertime from './core/attendance/overtime/Entry';
import Attendance from './core/attendance/attendance';
import Salary from './core/attendance/salary';
import Product from './pages/Base/Product';
import EntryProduct from './pages/Base/Product/Entry';
import Sale from './pages/Base/Transaction';
import History from './pages/Base/History';
import Project from './core/project';
import Home from './website/Home';
import GlobalStyle from './styles/GlobalStyle';
import Header from './website/component/Header';
import About from './website/About';
import Contact from './website/Contact';
import PrivacyPolicy from './website/PrivacyPolicy';
import Outlet from './core/outlet';
import EntryProject from './core/project/Entry';
import EntryOutlet from './core/outlet/Entry';

const App = () => {
    return (
        <AuthProvider>
            <Router>
                {/* <GlobalStyle /> */}
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/admin-login" element={<Auth />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/privacy-policy/:project" element={<PrivacyPolicy />} />
                    
                    <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
                    <Route path="/entity" element={<ProtectedRoute element={Entity}/>}/>
                    <Route path="/add-entity" element={<ProtectedRoute element={EntryEntity} />} />
                    <Route path="/edit-entity/:id" element={<ProtectedRoute element={EntryEntity} />} />                                
                    <Route path="/module" element={<ProtectedRoute element={Module} />} />
                    <Route path="/add-module" element={<ProtectedRoute element={EntryModule} />} />
                    <Route path="/edit-module/:id" element={<ProtectedRoute element={EntryModule} />} />
                    <Route path="/activity/auth" element={<ProtectedRoute element={Auth} />} />
                    <Route path="/activity/:link" element={<ProtectedRoute element={Activity} />} />
                            
                    <Route path="/activity-request/:id_module_action" element={<ProtectedRoute element={EntryRequest} />} />
                    <Route path="/edit-activity-request/:id_module_action/:id" element={<ProtectedRoute element={EntryRequest} />} />

                    <Route path="/activity-component/:id_module/:id_parent" element={<ProtectedRoute element={EntryActionCompnent} />} />
                    <Route path="/edit-activity-component/:id_module/:id_parent/:id" element={<ProtectedRoute element={EntryActionCompnent} />} />

                    {/* CORE */}
                    <Route path="/project" element={<ProtectedRoute element={Project}/>} />  
                    <Route path="/add-project" element={<ProtectedRoute element={EntryProject}/>} />
                    <Route path="/edit-project/:id" element={<ProtectedRoute element={EntryProject}/>} />

                    <Route path="/outlet/:project" element={<ProtectedRoute element={Outlet}/>} />  
                    <Route path="/add-outlet/:project" element={<ProtectedRoute element={EntryOutlet}/>} />
                    <Route path="/edit-outlet/:project/:id" element={<ProtectedRoute element={EntryOutlet}/>} />

                    <Route path="/users" element={<ProtectedRoute element={User}/>} />
                    <Route path="/add-user" element={<ProtectedRoute element={EntryUser}/>} />
                    <Route path="/edit-user/:id" element={<ProtectedRoute element={EntryUser}/>} />

                    <Route path="/employee" element={<ProtectedRoute element={Employee}/>} />
                    <Route path="/add-employee" element={<ProtectedRoute element={EntryEmployee}/>} />
                    <Route path="/edit-employee/:id" element={<ProtectedRoute element={EntryEmployee}/>} />

                    <Route path="/attendance-shift" element={<ProtectedRoute element={Shift}/>} />
                    <Route path="/add-attendance-shift" element={<ProtectedRoute element={EntryShift}/>} />
                    <Route path="/edit-attendance-shift/:id" element={<ProtectedRoute element={EntryShift}/>} />

                    <Route path="/attendance-schedule/:id_hs_employee" element={<ProtectedRoute element={EntrySchedule}/>} />
                    <Route path="/attendance/:id_hs_employee" element={<ProtectedRoute element={Attendance}/>} />

                    <Route path="/salary" element={<ProtectedRoute element={Salary}/>} />

                    <Route path="/attendance-overtime" element={<ProtectedRoute element={Employee}/>} />
                    <Route path="/add-attendance-overtime/:id_attendance" element={<ProtectedRoute element={EntryOvertime}/>} />
                    <Route path="/edit-attendance-overtime/:id_attendance/:id" element={<ProtectedRoute element={EntryOvertime}/>} />

                    {/* TEMPLATE */}
                    <Route path="/data-product" element={<ProtectedRoute element={Product}/>} />    
                    <Route path="/add-product" element={<ProtectedRoute element={EntryProduct}/>} />    
                    <Route path="/edit-product/:id" element={<ProtectedRoute element={EntryProduct}/>} />    

                    <Route path="/sale" element={<ProtectedRoute element={Sale}/>} />    
                    <Route path="/history" element={<ProtectedRoute element={History}/>} />    
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;