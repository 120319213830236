import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../context/AuthContext';

const HeaderContainer = styled.header`
    width: 100%;
    background: #20232a;
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
`;

const Nav = styled.nav`
    display: flex;
    gap: 20px;
    position: relative;
`;

const NavLink = styled(Link)`
    color: white;
    text-decoration: none;
    font-size: 1rem;
    position: relative;
    &:hover {
        text-decoration: underline;
    }
`;

const Submenu = styled.div`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    top: 100%;
    left: 0;
    background: #333;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SubmenuLink = styled(Link)`
    display: block;
    color: white;
    text-decoration: none;
    padding: 5px 10px;
    &:hover {
        background: #444;
    }
`;

const Header = () => {
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const [privacySubmenuOpen, setPrivacySubmenuOpen] = useState(false);
    const { auth, logout } = useAuth();

    const handleMouseEnter = () => setSubmenuOpen(true);
    const handleMouseLeave = () => setSubmenuOpen(false);

    const handlePrivacyMouseEnter = () => setPrivacySubmenuOpen(true);
    const handlePrivacyMouseLeave = () => setPrivacySubmenuOpen(false);

    if (auth.isAuthenticated) return null; // Do not render the header if not authenticated

    return (
        <HeaderContainer>
            <Logo>
                <img
                    src={`/assets/icon_network.png`}
                    alt="Logo"
                    className="w-10 h-auto mr-3"
                />                
            </Logo>
            <Nav>
                <NavLink to="/">Home</NavLink>
                <NavLink
                    to="#"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    About
                    <Submenu isOpen={submenuOpen}>
                        <SubmenuLink to="/about/team">Team</SubmenuLink>
                        <SubmenuLink to="/about/company">Company</SubmenuLink>
                        <SubmenuLink to="/about/careers">Careers</SubmenuLink>
                    </Submenu>
                </NavLink>
                <NavLink to="/contact">Contact</NavLink>
                <NavLink 
                    to="#"
                    onMouseEnter={handlePrivacyMouseEnter}
                    onMouseLeave={handlePrivacyMouseLeave}>Privacy Policy
                    <Submenu isOpen={privacySubmenuOpen}>
                        <SubmenuLink to="/privacy-policy/buffet-ahmad-salim">Buffet Ahmad Salim</SubmenuLink>
                    </Submenu>
                </NavLink>
                <NavLink to="/admin-login">Login</NavLink>
            </Nav>
        </HeaderContainer>
    );
};

export default Header;
