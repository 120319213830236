import React from 'react'
import LogoutButton from '../component/LogoutButton'

export default function Dashboard() {
  return (
    <div>
      <div>Dashboard</div>

      <LogoutButton />
    </div>
  )
}
