import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  padding: 20px;
`;

const About = () => {
    return (
        <PageContainer>
            <h1>About Us</h1>
            <p>This is the About page. Here you can provide information about your company or project.</p>
        </PageContainer>
    );
};

export default About;
