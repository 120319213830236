import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCalendarCheck, faCalendarTimes, faClock, faClockFour, faEdit, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../../component/Table/DataTable';
import { confirmDelete } from '../../../helpers/PromptUtils';
import { formatCurrency } from '../../../helpers/CurrencyInput';

const Employee = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);    
    
    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Nama',
            accessor: 'name',
        },
        {
            Header: 'Lahir',
            accessor: 'born',
        },
        {
            Header: 'Total Hari Kerja',
            accessor: 'main_days',
        },
        {
            Header: 'Gaji/Bln',
            accessor: 'salary',
            Cell: ({ value }) => formatCurrency(parseInt(value), 'IDR'),
        },
        {
            Header: 'Gaji/Hari',
            accessor: 'salary_day',
            Cell: ({ value }) => formatCurrency(parseInt(value), 'IDR'),
        },
        {
            Header: 'Tarif Lembur',
            accessor: 'overtime_rate',
            Cell: ({ value }) => formatCurrency(parseInt(value), 'IDR'),
        },        
    ];

    // Fetch data from API
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}api/core/attendance/employee?id_project=1`)
            .then(response => response.json())
            .then(data => {
                setData(data); // Set fetched data
                setLoading(false); // Data fetching complete
            })
            .catch(error => {
                setError(error); // Handle errors
                setLoading(false); // Data fetching complete
            });
    }, []);

    const handleEdit = (rowData) => {
        navigate(`/edit-employee/${rowData.id}`);
    };

    const handleSchedule = (rowData) => {
        navigate(`/attendance-schedule/${rowData.id}`);
    };

    const handleAttendance = (rowData) => {
        navigate(`/attendance/${rowData.id}`);
    };

    const handleRemove = (rowData) => {
        const confirmed = confirmDelete('Are you sure you want to delete this module?');
        if (confirmed) {
            axios({
                method: 'POST',
                url: process.env.REACT_APP_API_BASE_URL + 'api/core/attendance/employee/delete',
                data: {
                    id: rowData.id
                }
            }).then(function (response) {
                window.location.reload();
            });            
        }
    }

    const actions = [{
        child: <button className='text-white text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 mx-1'>
            <FontAwesomeIcon icon={faTrash} color="white" />
        </button>,
        callback: (rowData) => handleRemove(rowData)
    },{
        child: <button className='text-white text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1'>
            <FontAwesomeIcon icon={faEdit} color="white" />
        </button>,
        callback: (rowData) => handleEdit(rowData)
    },{
        child: <button className='text-white text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1'>
            <FontAwesomeIcon icon={faClock} color="white" />
        </button>,
        callback: (rowData) => handleSchedule(rowData)
    },{
        child: <button className='text-white text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1'>
            <FontAwesomeIcon icon={faCalendarCheck} color="white" />
        </button>,
        callback: (rowData) => handleAttendance(rowData)
    }];

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div>
            <div className='flex justify-between'>
                <h1>Employee</h1>

                <div>
                    <button
                        className="text-white text-sm rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 my-2"
                        type="button"
                        onClick={() => {
                            navigate('/add-employee');
                        }}>
                        <FontAwesomeIcon icon={faAdd} color="white" /> Tambah
                    </button>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={data}
                actions={actions}
            />
        </div>
    );
};

export default Employee;
