// src/components/ActionComponent.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import RenderComponent from '../../../component/Component';
import { useNavigate } from 'react-router-dom';
import { confirmDelete } from '../Helpers/PromptUtils';

const ActionComponent = ({ action }) => {
    const custom = true;
    const [entities, setEntities] = useState([]);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const arrayToObject = (array) => {
        return array.reduce((accumulator, current) => {
            if (current.key && current.value !== undefined) {
                accumulator[current.key] = current.value;
            }
            return accumulator;
        }, {});
    };
    
    const convertObjectToArray = (obj) => {
        return Object.entries(obj).map(([key, value]) => ({ key, value }));
    };
    
    const collectData = (array = [], keys = []) => {
        return array.map(item => {
            const result = {};
            keys.forEach(key => {
                if (item.hasOwnProperty(key)) {
                    result[key] = item[key];
                }
            });
            return result;
        });
    };

    const renderItem = (item, isDisplayID = false) => {
        const itemsArray = convertObjectToArray(item);
        if (itemsArray.length === 0) {
            return <p>No items to display</p>;
        }

        const excludedKeys = new Set(['created_at', 'updated_at', 'updated_by']);

        const filteredItems = itemsArray.filter(item => !excludedKeys.has(item.key));

        return (
            <div className="card-grid">
                {filteredItems.map((item, index) => {
                    if (item.key === 'id' || item.key === 'id_product') {
                        return null;
                    }
                    return (
                        <div key={`${item.key}_${index}`} className='d-flex text-left'>
                            <p>{item.key}</p>
                            <p><strong>{item.value}</strong></p>
                        </div>
                    )
                })}
            </div>
        );
    };

    const fetchData = async (catchResponse) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_BASE_URL}api/dashboard/request?url=${action.action_request.url}`
            });

            const data = response?.data?.result || [];
            setEntities(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            const confirmed = confirmDelete('Are you sure you want to delete this component?');
            if (confirmed) {
                axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_BASE_URL + 'api/dashboard/component/delete',
                    data: {
                        id: id
                    }
                }).then(function (response) {
                    window.location.reload();
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setEntities([]);
        setItems([]);
        setLoading(true);
        if (action.element === 'grid' && action?.action_request?.method === 'GET') {
            fetchData(action?.action_response || []);
        } else if (action.element === 'table' && action?.action_request?.method === 'GET') {
            fetchData(action?.action_response || []);
        } else if (action.element === 'list' && action?.action_request?.method === 'GET') {
            fetchData(action?.action_response || []);
        } else {
            setLoading(false);
        }
    }, [action]);

    useEffect(() => {
        const itemsArray = entities.map(item => renderItem(item));
        setItems(itemsArray);
    }, [entities]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (action.element === 'button') {
        return (
            <RenderComponent
                type={action.element}
                component={{
                    title: `${action.element} (Component ${action.id})`                    
                }}
                attribute={arrayToObject(action.action_attribute)}
                actionRemove={(data) => {
                    handleDelete(action.id);
                }}
                actionEdit={(data) => {                    
                    navigate(`/edit-activity-component/${action.id_module}/${action.id_parent}/${action.id}`);
                }}
                actionRequest={(data) => {
                    if (action.action_request?.id) {
                        navigate(`/edit-activity-request/${action.id}/${action.action_request.id}`);
                    } else {
                        navigate(`/activity-request/${action.id}`);
                    }
                }}
                callRequest={ async (data) => {
                    const response = await axios({
                        method: action.action_request.method,
                        url: `${process.env.REACT_APP_API_BASE_URL}api/${action.action_request.url}`
                    });

                    console.log(response);
                }}
            />
        );
    }

    if (action.element === 'input') {
        return (
            <RenderComponent
                type={action.element}
                component={{
                    title: `${action.element} (Component ${action.id})`                    
                }}
                attribute={arrayToObject(action.action_attribute)}
                actionRemove={(data) => {
                    handleDelete(action.id);
                }}
                actionEdit={(data) => {                    
                    navigate(`/edit-activity-component/${action.id_module}/${action.id_parent}/${action.id}`);
                }}
                actionRequest={(data) => {
                    if (action.action_request?.id) {
                        navigate(`/edit-activity-request/${action.id}/${action.action_request.id}`);
                    } else {
                        navigate(`/activity-request/${action.id}`);
                    }
                }}
                callRequest={ async (data) => {
                    const response = await axios({
                        method: action.action_request.method,
                        url: `${process.env.REACT_APP_API_BASE_URL}api/${action.action_request.url}`
                    });

                    console.log(response);
                }}
            />
        );
    }

    if (action.element === 'grid') {  
        return <RenderComponent
            enableCustom={custom}
            type={action.element}
            component={{ title: `${action.element} (Component ${action.id})`, items }}
            attribute={arrayToObject(action.action_attribute)}
            actionRemove={(data) => {
                handleDelete(action.id);
            }}
            actionEdit={(data) => {
                navigate(`/edit-activity-component/${action.id_module}/${action.id_parent}/${action.id}`);
            }}
            actionRequest={(data) => {
                if (action.action_request?.id) {
                    navigate(`/edit-activity-request/${action.id}/${action.action_request.id}`);
                } else {
                    navigate(`/activity-request/${action.id}`);
                }
            }}            
        />;
    }

    if (action.element === 'list') {  
        return <RenderComponent
            enableCustom={custom}
            type={action.element}
            component={{ title: `${action.element} (Component ${action.id})`, items }}
            attribute={arrayToObject(action.action_attribute)}
            actionRemove={(data) => {
                handleDelete(action.id);
            }}
            actionEdit={(data) => {
                navigate(`/edit-activity-component/${action.id_module}/${action.id_parent}/${action.id}`);
            }}
            actionRequest={(data) => {
                if (action.action_request?.id) {
                    navigate(`/edit-activity-request/${action.id}/${action.action_request.id}`);
                } else {
                    navigate(`/activity-request/${action.id}`);
                }
            }}
        />;
    }

    if (action.element === 'table') {
        const keys = entities.length > 0 ? Object.keys(entities[0]) : [];

        const columns = keys.map(key => ({
            Header: key.charAt(0).toUpperCase() + key.slice(1),
            accessor: key
        }));
        return (
            <RenderComponent
                enableCustom={custom}
                type={action.element}
                component={{
                    title: `${action.element} (Component ${action.id})`,
                    items: entities,
                    columns: columns
                }}
                actionRemove={(data) => {
                    handleDelete(action.id);
                }}
                actionEdit={(data) => {
                    navigate(`/edit-activity-component/${action.id_module}/${action.id_parent}/${action.id}`);
                }}
                actionRequest={(data) => {
                    if (action.action_request?.id) {
                        navigate(`/edit-activity-request/${action.id}/${action.action_request.id}`);
                    } else {
                        navigate(`/activity-request/${action.id}`);
                    }
                }}
            />
        );
    }

    return <div>Unknown element type</div>;
};

export default ActionComponent;
