// src/components/DynamicComponent.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ActionComponent from './ActionComponent';
import Modal from '../../../component/Modal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { confirmDelete } from '../Helpers/PromptUtils'

const DynamicComponent = ({ component }) => {
    const custom = true;
    const { id_module, component_id: id, component_parent : parent, children } = component;
    const [actions, setActions] = useState([]);

    const [modalConfig, setModalConfig] = useState({
        visible: false,
        title: '',
        fields: []
    });

    const openModal = (type, parent) => {
        let fields = [];
        let title = "";

        if (type === 'action') {
            title = "Action";
            console.log({ id_module, title, parent });
        } else if (type === 'layout') {
            title = "Layout";
            fields = [                
                {
                    column: 'element',
                    column_type: 'text',
                    input_type: 'text',
                    value: '',
                }
            ];
            setModalConfig({
                visible: true,
                title: title,
                fields: fields
            });
            return;
        } else {
            alert("Nothing");
            return;
        }
    };

    const closeModal = () => setModalConfig({
        visible: false,
        title: '',
        fields: []
    });

    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: '1',
                id_module: id_module,
                id_parent: id,
                element: values.element,
            };

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/component`, formData);

            window.location.reload();
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error, show alert or set form error state
        } finally {
            requests.setSubmitting(false);
        }
    };    

    const removeComponent = (id) => {
        const confirmed = confirmDelete('Are you sure you want to delete this component?');
        if (confirmed) {
            axios({
                method: 'POST',
                url: process.env.REACT_APP_API_BASE_URL + 'api/dashboard/component/delete',
                data: {
                    id: id
                }
            }).then(function (response) {
                window.location.reload();
            });            
        }
    }

    useEffect(() => {
        const fetchActions = async () => {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_API_BASE_URL}api/dashboard/action?id_parent=${id}`
                });

                setActions(response.data.action);
            } catch (error) {
                console.error(`Error executing action for component ${id}:`, error);
            }
        };

        fetchActions();
    }, [id]);


    return (
        <div style={{ padding: '10px', width: '100%', border: '1px dashed grey', display: 'flex' }}>
            {modalConfig.visible && <Modal title={modalConfig.title} fields={modalConfig.fields} closeModal={closeModal} submitModal={onSubmit} />}

            {children && children.length > 0 && (
                <div className='w-full flex'>
                    {children.map((child, index) => (
                        <DynamicComponent key={index} component={child} />
                    ))}
                </div>
            )}
            
            <div className={actions?.length > 0 ? 'w-full' : 'w-auto'}>
                <b className='text-xs font-bold'>Layout {id}</b>
                {
                    actions?.map((action, index) => {
                        return <ActionComponent key={index} action={action} />
                    })
                }
                {custom && 
                    <div className='flex'>
                        {parent != 0 ? <button onClick={() => removeComponent(id)} className="text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 m-2">
                            <FontAwesomeIcon icon={faTrashAlt} color="white" />
                        </button> : null}                    
                        <button onClick={() => openModal('layout', id ?? 0)} className='text-xs bg-slate-200 rounded p-2 m-2 hover:bg-gray-700'>+ Layout</button>
                        <Link className="text-xs bg-slate-200 rounded p-2 m-2 hover:bg-gray-700" to={`/activity-component/${id_module}/${id}`}>+ Component</Link>
                    </div>}
            </div>
        </div>
    );

};

export default DynamicComponent;
