import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

const EntryRequest = () => {
  // /edit-activity-request/:id_module_action/:id
  const { id, id_module_action } = useParams(); // Get 'id' from URL params
  const navigate = useNavigate();

  // State for initial values
  const [initialValues, setInitialValues] = useState({
    name: '',
    url: '',
    method: '',
    description: '',
  });

  const [fields, setFields] = useState([{ id: 1, column: '', inputType: '', defaultValue: '' }]);
  const [fieldResponse, setFieldResponse] = useState([{ id: 1, column: '', inputType: '', defaultValue: '' }]);

  const [moves, setMoves] = useState([{
    id: 1,
    id_module_request: '',
    name: '',
    table: '',
    table_move: '',
    type: '',
    description: ''
  }]);

  // Fetch initial data based on 'id'
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/system/request/detail?id=${id}`);
          const { name, url, method, description } = response.data.data;
          const { fields: fetchedFields } = response.data;
          const { fields_response: fetchedFieldsResponse } = response.data;

          setInitialValues({
            name: name || '',
            url: url || '',
            method: method || '',
            description: description || '',
          });

          // Set dynamic fields from fetched data
          if (fetchedFields && fetchedFields.length > 0) {
            const mappedFields = fetchedFields.map((field, index) => ({
              id: index + 1,
              column: field.column || '',
              inputType: field.type || '',
              defaultValue: field.default || '',
            }));
            setFields(mappedFields);
          }

          if (fetchedFieldsResponse && fetchedFieldsResponse.length > 0) {
            const mappedFields = fetchedFieldsResponse.map((field, index) => ({
              id: index + 1,
              column: field.column || '',
              inputType: field.type || '',
              defaultValue: field.default || '',
            }));
            setFieldResponse(mappedFields);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  // Function to add a new field
  const addField = (x) => {
    if (x == 'request') {
      const newFields = [...fields];
      newFields.push({ id: newFields.length + 1, inputType: '', defaultValue: '' });
      setFields(newFields);
    } else if (x == 'response') {
      const newFields = [...fieldResponse];
      newFields.push({ id: newFields.length + 1, inputType: '', defaultValue: '' });
      setFieldResponse(newFields);
    }else if (x == 'moves') {
      const newFields = [...moves];
      newFields.push({ id: newFields.length + 1, inputType: '', defaultValue: '' });
      setMoves(newFields);
    }
  };

  // Function to remove a field
  const removeField = (x, id) => {
    if (x == 'request') {
      const newFields = fields.filter((field) => field.id !== id);
      setFields(newFields);
    } else if (x == 'response') {
      const newFields = fieldResponse.filter((field) => field.id !== id);
      setFieldResponse(newFields);
    }else if (x == 'moves') {
      const newFields = moves.filter((field) => field.id !== id);
      setMoves(newFields);
    }

  };

  // Handle input change for dynamic fields
  const handleInputChange = (x, id, fieldKey, value) => {
    if (x == 'request') {
      const newFields = fields.map((field) =>
        field.id === id ? { ...field, [fieldKey]: value } : field
      );
      setFields(newFields);

    } else if (x == 'response') {
      const newFields = fieldResponse.map((field) =>
        field.id === id ? { ...field, [fieldKey]: value } : field
      );
      setFieldResponse(newFields);
    } else if (x == 'moves') {
      const newFields = moves.map((field) =>
        field.id === id ? { ...field, [fieldKey]: value } : field
      );
      setMoves(newFields);
    }
  };

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    method: Yup.string().required('Method is required'),
    description: Yup.string().required('Description is required'),
  });

  // Handle form submission
  const onSubmit = async (values, requests) => {
    try {
      const formData = {
        id_project: '1',
        id_module_action,
        name: values.name,
        url: values.url,
        method: values.method,
        description: values.description,
        active: 'Y',
        fields: JSON.stringify(fields),
        fields_response: JSON.stringify(fieldResponse),
      };

      if (id) {
        formData['id'] = id;
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/system/request`, formData);
      } else {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/system/request`, formData);
      }

      // navigate(-1);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error, show alert or set form error state
    } finally {
      requests.setSubmitting(false);
    }
  };

  return (
    <div className="mx-auto p-4 bg-white shadow-md rounded">
      <h2 className="text-2xl font-bold mb-4">Entry Form Request</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {/* Form fields */}
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                placeholder="Name"
                type="text"
                name="name"
                defaultValue={initialValues.name} // Bind value to Formik values                
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.name && touched.name && (
                <p className="text-red-500 text-xs mt-1">{errors.name}</p>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <input
                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                placeholder="Description"
                type="textarea"
                name="description"
                defaultValue={initialValues.description} // Bind value to Formik values                
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.description && touched.description && (
                <p className="text-red-500 text-xs mt-1">{errors.description}</p>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                PATH URL
              </label>
              <input
                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                placeholder="/api/..."
                type="text"
                name="url"
                defaultValue={initialValues.url} // Bind value to Formik values                
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.url && touched.url && (
                <p className="text-red-500 text-xs mt-1">{errors.url}</p>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="method" className="block text-sm font-medium text-gray-700">
                Method
              </label>
              <select
                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                name="method"
                defaultValue={initialValues.method} // Bind value to Formik values                
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="GET">GET</option>
                <option value="PUT">PUT</option>
                <option value="POST">POST</option>
                <option value="DELETE">DELETE</option>
              </select>
              {errors.method && touched.method && (
                <p className="text-red-500 text-xs mt-1">{errors.method}</p>
              )}
            </div>            
            <div className='flex'>
              <div className='flex-1 p-2'>
                <div className='flex justify-between my-2'>
                  <h1 className='p-2'>Request</h1>
                  <button
                    className="text-xs rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 m-1"
                    type="button"
                    onClick={() => addField('request')}>
                    <FontAwesomeIcon icon={faAdd} color="white" />
                  </button>
                </div>
                <hr />
                {fields.map((field) => (
                  <div key={field.id} className="flex items-center mb-2 flex-1">
                    <input
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Column"
                      type="text"
                      name={`column_${field.id}`}
                      value={field.column}
                      onChange={(e) => handleInputChange('request', field.id, 'column', e.target.value)}
                    />
                    <select
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      name={`input_type_${field.id}`}
                      value={field.inputType}
                      onChange={(e) => handleInputChange('request', field.id, 'inputType', e.target.value)}
                      defaultValue={"TEXT"}
                    >
                      <option value="TEXT">TEXT</option>
                      <option value="OBJECT">OBJECT</option>
                      <option value="ARRAY">ARRAY</option>
                    </select>

                    <input
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Default Value"
                      type="text"
                      name={`default_value_${field.id}`}
                      value={field.defaultValue}
                      onChange={(e) => handleInputChange('request', field.id, 'defaultValue', e.target.value)}
                    />

                    {/* Remove field button */}
                    <button
                      className="rounded focus:ring bg-red-500 hover:bg-red-700 p-2"
                      onClick={() => removeField('request', field.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} color="white" />
                    </button>
                  </div>
                ))}
              </div>
              <div className='flex-1 p-2'>
                <div className='flex justify-between my-2'>
                  <h1 className='p-2'>Response</h1>
                  <button
                    className="text-xs rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 m-1"
                    type="button"
                    onClick={() => addField('response')}>
                    <FontAwesomeIcon icon={faAdd} color="white" />
                  </button>
                </div>
                <hr />
                {fieldResponse.map((field) => (
                  <div key={field.id} className="flex items-center mb-2 flex-1">
                    <input
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Column"
                      type="text"
                      name={`column_${field.id}`}
                      value={field.column}
                      onChange={(e) => handleInputChange('response', field.id, 'column', e.target.value)}
                    />
                    <select
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      name={`input_type_${field.id}`}
                      value={field.inputType}
                      onChange={(e) => handleInputChange('response', field.id, 'inputType', e.target.value)}
                      defaultValue={"TEXT"}
                    >
                      <option value="TEXT">TEXT</option>
                      <option value="OBJECT">OBJECT</option>
                      <option value="ARRAY">ARRAY</option>
                    </select>

                    <input
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Default Value"
                      type="text"
                      name={`default_value_${field.id}`}
                      value={field.defaultValue}
                      onChange={(e) => handleInputChange('response', field.id, 'defaultValue', e.target.value)}
                    />

                    {/* Remove field button */}
                    <button
                      className="rounded focus:ring bg-red-500 hover:bg-red-700 p-2"
                      onClick={() => removeField('response', field.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} color="white" />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className='flex-1 p-2'>
                <div className='flex justify-between my-2'>
                  <h1 className='p-2'>Moves</h1>
                  <button
                    className="text-xs rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 m-1"
                    type="button"
                    onClick={() => addField('moves')}>
                    <FontAwesomeIcon icon={faAdd} color="white" />
                  </button>
                </div>
                <hr />
                {moves.map((field) => (
                  // name
                  // table
                  // table_move
                  // type
                  <div key={field.id} className="flex items-center mb-2 flex-1">
                    <input
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Name"
                      type="text"
                      name={`name_${field.id}`}
                      value={field.name}
                      onChange={(e) => handleInputChange('moves', field.id, 'name', e.target.value)}
                    />
                    <select
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      name={`input_type_${field.id}`}
                      value={field.type}
                      onChange={(e) => handleInputChange('moves', field.id, 'type', e.target.value)}
                      defaultValue={"SELECT"}
                    >
                      <option value="SELECT">SELECT</option>
                      <option value="INSERT">INSERT</option>
                      <option value="UPDATE">UPDATE</option>
                      <option value="DELETE">DELETE</option>
                      <option value="INSERT_BATCH">INSERT_BATCH</option>
                    </select>

                    {/* <input
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Default Value"
                      type="text"
                      name={`default_value_${field.id}`}
                      value={field.defaultValue}
                      onChange={(e) => handleInputChange('moves', field.id, 'defaultValue', e.target.value)}
                    /> */}

                    {/* Remove field button */}
                    <button
                      className="rounded focus:ring bg-red-500 hover:bg-red-700 p-2"
                      onClick={() => removeField('moves', field.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} color="white" />
                    </button>
                  </div>
                ))}
              </div>

            <div className="mt-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EntryRequest;
