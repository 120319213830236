import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

const EntryEntity = () => {
    const { id, } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [initialValues, setInitialValues] = useState({
        id: '',
        name: '',
        category: '',
        description: '',
        table: '',
    });

    const [fields, setFields] = useState([{ id: 1, column: '', columnType: '', inputType: '', defaultValue: '' }]);

    useEffect(() => {
        fetchData();
    }, [id]);

    const fetchData = async () => {
        setLoading(true);
        try {
            if (id) {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/system/entity/detail?id=${id}`);
                const { name, category, table, description } = response.data.data;
                const { fields: fetchedFields } = response.data;

                setInitialValues({
                    name: name || '',
                    category: category || '',
                    table: table || '',
                    description: description || '',
                });

                // Set dynamic fields from fetched data
                if (fetchedFields && fetchedFields.length > 0) {
                    const mappedFields = fetchedFields.map((field, index) => ({
                        id: index + 1,
                        column: field.column || '',
                        columnType: field.column_type || '',
                        inputType: field.input_type || '',
                        defaultValue: field.default || '',
                    }));
                    setFields(mappedFields);
                }

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const addField = () => {
        const newFields = [...fields];
        newFields.push({ id: newFields.length + 1, column: '', columnType: '', inputType: '', defaultValue: '' });
        setFields(newFields);
    };

    const removeField = (id) => {
        const newFields = fields.filter((field) => field.id !== id);
        setFields(newFields);
    };

    const handleInputChange = (id, fieldKey, value) => {
        const newFields = fields.map((field) =>
            field.id === id ? { ...field, [fieldKey]: value } : field
        );
        setFields(newFields);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        category: Yup.string().required('Category is required'),
        description: Yup.string().required('Description is required'),
        table: Yup.string().required('Table is required'),        
    });

    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: '1',
                category: values.category,
                name: values.name,
                description: values.description,
                table: values.table,
                active: 'Y',
                fields: JSON.stringify(fields)
            };

            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/system/entity`, formData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/system/entity`, formData);
            }

            navigate(-1);
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error, show alert or set form error state
        } finally {
            requests.setSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 bg-white shadow-md rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form Entity</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="name">Name</label>
                            <Field 
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" name="name" />
                            <ErrorMessage name="name" component="p" className='text-red-500 text-xs mt-1'/>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="category">Category</label>
                            <Field 
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" name="category" />
                            <ErrorMessage name="category" component="p" className='text-red-500 text-xs mt-1'/>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="description">Description</label>
                            <Field 
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="textarea" name="description" />
                            <ErrorMessage name="description" component="p" className='text-red-500 text-xs mt-1'/>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="table">Table</label>
                            <Field 
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" name="table" />
                            <ErrorMessage name="table" component="p" className='text-red-500 text-xs mt-1'/>
                        </div>
                        <div className='flex justify-between my-2'>
                            <h1 className='p-2'>Column</h1>
                            <button
                                className="text-xs rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 m-1"
                                type="button"
                                onClick={() => addField('request')}>
                                <FontAwesomeIcon icon={faAdd} color="white" />
                            </button>
                        </div>
                        <hr />
                        {fields.map((field) => (
                            <div key={field.id} className="flex items-center mb-2 flex-1 p-2">
                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Column"
                                    type="text"
                                    name={`column_${field.id}`}
                                    value={field.column}
                                    onChange={(e) => handleInputChange(field.id, 'column', e.target.value)}
                                />
                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Column Type"
                                    type="text"
                                    name={`column_type_${field.id}`}
                                    value={field.columnType}
                                    onChange={(e) => handleInputChange(field.id, 'columnType', e.target.value)}
                                />

                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Input Type"
                                    type="text"
                                    name={`input_type_${field.id}`}
                                    value={field.inputType}
                                    onChange={(e) => handleInputChange(field.id, 'inputType', e.target.value)}
                                />

                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Default Value"
                                    type="text"
                                    name={`default_value_${field.id}`}
                                    value={field.defaultValue}
                                    onChange={(e) => handleInputChange(field.id, 'defaultValue', e.target.value)}
                                />

                                <button
                                    className="text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2"
                                    onClick={() => removeField(field.id)}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} color="white" />
                                </button>
                            </div>
                        ))}

                        <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryEntity;
