import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const EntryModule = () => {
    const { id, } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({
        id: '',
        name: '',
        category: '',
        link: '',
        description: ''
    });

    useEffect(() => {        
        if (id) {
            fetchData();
        }
    }, [id]);

    const fetchData = async () => {
        setLoading(true);
        try {            
            await axios.get(`${process.env.REACT_APP_API_URL}/api/system/module/detail?id=${id}`).then( response => {
                const { name, link, category, description } = response.data.data;
                setInitialValues({
                    name: name || '',
                    link: link || '',
                    category: category || '',
                    description: description || ''
                });
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        category: Yup.string().required('Category is required'),
        link: Yup.string().required('Link is required'),
        description: Yup.string().required('Description is required'),
    });

    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: '1',
                name: values.name,
                category: values.category,
                link: values.link,
                description: values.description,                
            };

            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_URL}api/system/module`, formData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}api/system/module`, formData);
            }
            
            navigate(-1);
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            requests.setSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 bg-white shadow-md rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form Module</h2>
            <Formik
                enableReintialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form 
                        onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="name">Name</label>
                            <Field 
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" name="name" />
                            <ErrorMessage name="name" component="p" className='text-red-500 text-xs mt-1'/>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="category">Category</label>
                            <Field 
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" name="category" />
                            <ErrorMessage name="category" component="p" className='text-red-500 text-xs mt-1'/>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="link">Link</label>
                            <Field 
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" name="link" />
                            <ErrorMessage name="link" component="p" className='text-red-500 text-xs mt-1'/>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="description">Description</label>
                            <Field 
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" name="description" />
                            <ErrorMessage name="description" component="p" className='text-red-500 text-xs mt-1'/>
                        </div>
                        <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}>
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryModule;
