import React from 'react';

import { motion } from "framer-motion"
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #282c34;
  color: white;
  text-align: center;
  padding: 0 20px;
`;

const Title = styled.h1`
  font-size: 4rem;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 40px;
`;

const Button = styled(motion.button)`
  background: #61dafb;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #21a1f1;
  }
`;

const Home = () => {
  return (
    <Container>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Title>Buffet Ahmad Salim</Title>
        <Subtitle>Keseimbangan rasa menciptakan harmoni di setiap gigitan.</Subtitle>
        <Button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Get Started
        </Button>
      </motion.div>
    </Container>
  );
};

export default Home;
