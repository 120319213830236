import React, { useState, useEffect } from 'react';
import DataTable from '../../component/Table/DataTable';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { confirmDelete } from '../Activity/Helpers/PromptUtils';
import axios from 'axios';

const Entity = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Define columns
    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Nama',
            accessor: 'name',
        },
        {
            Header: 'Category',
            accessor: 'category',
        },
        {
            Header: 'Table',
            accessor: 'table',
        },
    ];

    // Fetch data from API
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}api/system/entity`)
            .then(response => response.json())
            .then(data => {
                setData(data.data); // Set fetched data
                setLoading(false); // Data fetching complete
            })
            .catch(error => {
                setError(error); // Handle errors
                setLoading(false); // Data fetching complete
            });
    }, []);

    // Conditional rendering
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleEdit = (rowData) => {
        navigate(`/edit-entity/${rowData.id}`);
    };

    const handleRemove = (rowData) => {
        const confirmed = confirmDelete('Are you sure you want to delete this entity?');
        if (confirmed) {
            axios({
                method: 'POST',
                url: process.env.REACT_APP_API_BASE_URL + 'api/system/entity/delete',
                data: {
                    id: rowData.id
                }
            }).then(function (response) {
                window.location.reload();
            });            
        }
    }

    const handleExecute = (rowData) => {
        const confirmed = confirmDelete('Are you sure you want to execute this entity?');
        if (confirmed) {
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_BASE_URL}api/system/entity/execute?id=${rowData.id}` ,                
            }).then(function (response) {
                console.log(response);
                // window.location.reload();
            });            
        }
    }

    const actions = [{
        child: <button className='text-white text-xs rounded focus:ring bg-gray-500 hover:bg-gray-700 p-2 mx-1'>Eksekusi</button>,
        callback: (rowData) => handleExecute(rowData)
    },{
        child: <button className='text-white text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 mx-1'>Hapus</button>,
        callback: (rowData) => handleRemove(rowData)
    },{
        child: <button className='text-white text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1'>Ubah</button>,
        callback: (rowData) => handleEdit(rowData)
    }];

    return (
        <div>
            <div className='flex justify-between'>
                <h1>Entity</h1>
                <button
                    className="text-white text-xs rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 my-2"
                    type="button"
                    onClick={() => {
                        navigate('/add-entity');
                    }}>
                    <FontAwesomeIcon icon={faAdd} color="white" /> Tambah
                </button>
            </div>
            <DataTable
                columns={columns}
                data={data}
                actions={actions}
            />
        </div>
    );
};

export default Entity;
